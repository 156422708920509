import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { BioCard } from "../Components/elements"
import curriculumsMock from "../__mocks__/curriculums"

export const NosotrosPage = () => {
  return (
    <section className="inner-page-section">
      <Container>
        <Row>
          <Col>
            <div className="regular-page-header">
              <h1>Nosotros</h1>
              <p>Los que hacemos DFS contamos con más de 30 años desarrollando nuestras carreras en las principales empresas del Mercado de Capitales Argentino e Internacional. Buscando siempre rentabilidad y protección para sus ahorros.</p>
              <p>Estableciendo sólidos vínculos con nuestros clientes, que confían en nosotros y descansan en la tranquilidad que sólo una larga trayectoria puede brindar, los acompañamos a lo largo del tiempo ofreciendo soluciones a la medida de sus necesidades e intereses.</p>
              <p>Nuestro reconocido equipo de trabajo, especialista en el diseño de estrategias de inversión, en cumplimiento de las regulaciones de los distintos organismos, con transparencia y ética profesional, hacen de DFS una nueva alternativa para el cuidado y crecimiento de su patrimonio.</p>
            </div>

            <div className="regular-page-header">
              <h2>Visión</h2>
              <p>Ser su Compañía de Servicios Financieros de preferencia, brindando soluciones financieras integrales. Proveer  una visión global con independencia de criterio.</p>
            </div>

            <div className="regular-page-header">
              <h2>Valores Corporativos & Cultura Organizacional</h2>
              <p>Establecer relaciones de largo plazo. Operar de manera ética y transparente. Ser dignos de confianza. Trabajar en equipo, buscando la optimización de resultados para nuestros clientes.</p>
            </div>

            <div className="regular-page-header">
              <h2>Nuestro Equipo</h2>
              <p>Contamos con un equipo de profesionales con más de 25 años de
              amplia y probada experiencia administrando activos financieros
              argentinos y globales de compañias de asset management, de libros
              propietarios de bancos extranjeros lideres de mercado, de
              compañías de seguros de primera línea, y de Banca Privada.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <div className="biocard-wrapper">
              {curriculumsMock.map((x) => (
                <BioCard
                  nombre={x.nombre}
                  puesto={x.puesto}
                  bio={x.bio}
                  link={x.link}
                  imagen={x.imagen}
                  cargos={x.cargos}
                ></BioCard>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
      <Row className="mt-5 pt-5 text-center">
        <Col>
          <h6><a href={"https://www.cnv.gov.ar/SitioWeb/RegistrosPublicos/Idoneos"}>Conocé el listado completo de nuestros asesores idóneos acá.</a></h6>
        </Col>
      </Row>
      </Container>
    </section>
  )
}
